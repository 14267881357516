// v2.0
const CryptoJS = require('crypto-js');
const aes = require('crypto-js/aes');
const encHex = require('crypto-js/enc-hex');
const padZeroPadding = require('crypto-js/pad-zeropadding');

function base64Encode(string) {
    const wordArray = CryptoJS.enc.Utf8.parse(string);
    const encoded = CryptoJS.enc.Base64.stringify(wordArray);
    return encoded;
}

function base64Decode(base64) {
    const parsedWordArray = CryptoJS.enc.Base64.parse(base64);
    const decoded = parsedWordArray.toString(CryptoJS.enc.Utf8);
    return decoded;
}

function getRanHex(size) {
    let result = [];
    let hexRef = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
    ];

    for (let n = 0; n < size; n++) {
        result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join('');
}

function encrypt(data, secret_key) {
    const string_convert = JSON.stringify(data);
    let key = encHex.parse(secret_key);
    let iv = encHex.parse(secret_key);
    let encrypted = aes
        .encrypt(string_convert, key, { iv: iv, padding: padZeroPadding })
        .toString();
    return encrypted;
}

function decrypt(encrypted, secret_key) {
    let key = encHex.parse(secret_key);
    let iv = encHex.parse(secret_key);
    let data = aes
        .decrypt(encrypted, key, { iv: iv, padding: padZeroPadding })
        .toString(CryptoJS.enc.Utf8);
    return JSON.parse(data);
}

module.exports = {
    encrypt,
    decrypt,
    getRanHex,
    base64Encode,
    base64Decode,
};
