export const pageview = url => {
    window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
    })
}

export const event = ({ action, category, label, value }) => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    })
}

export const purchaseGtag = (id, event_category, value, itemId, utm_source, utm_campaign, utm_medium, utm_action, utm_platform) => {
    if (utm_source && utm_medium && utm_campaign && utm_action) {
        window.gtag("event", 'purchase', {
            transaction_id: id,
            event_category: event_category,
            value: value,
            currency: "THB",
            items: [
                {
                    item_id: itemId,
                    index: 0,
                    quantity: 1
                }],
            utm_source: utm_source,
            utm_campaign: utm_campaign,
            utm_medium: utm_medium,
            utm_action: utm_action,
            utm_platform: utm_platform,
        })
    } else {
        window.gtag("event", 'purchase', {
            transaction_id: id,
            event_category: event_category,
            value: value,
            currency: "THB",
            items: [
                {
                    item_id: itemId,
                    index: 0,
                    quantity: 1
                }],
        })
    }
}

export const conversionGtag = (id, event_category, value) => {
    window.gtag("event", 'conversion', {
        transaction_id: id,
        event_category: event_category,
        value: value,
        currency: 'THB'
    });
}

export const conversionGtagOtherEvent = (event_category) => {
    window.gtag("event", 'conversion', {
        event_category: event_category
    });
}