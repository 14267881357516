export const AuthConfigs = {
    admin_token_name: 'vip19_admin_auth',
    member_token_name: 'vip29_member_auth',
};

export const booking_status = [
    'success',
    'cancel',
    'pending',
    'failed',
    'refund',
];

export const pinvest_emp_admin_approve =
    process.env.TEST_MODE === 'YES' ? 'PIN130' : 'PIN052';

export const bookingFormats = {
    full_day: {
        name: 'Full day',
        hour: 10,
        price: 8500, // thb
    },
    pick_up: {
        name: 'Pick up',
        hour: 5,
        price: 3500,
    },
};

export const termOfPayments = {
    deposit_half: {
        name: 'Deposit 50%',
    },
    full_payment: {
        name: 'Full Payment',
    },
};

export const genders = ['male', 'female'];
