/* eslint  @next/next/no-img-element: off */
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import styles from './Vip29LiffNavbar.module.scss';
import CarLogo from '../../assets/images/logos/logo-full.png';

const LinePic = (props) => {
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    return (
        <Stack direction="row" spacing={2}>
            <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
            >
                <Avatar alt="Remy Sharp" src={props.pic} />
            </StyledBadge>
        </Stack>
    );
};

const Vip29LiffNavbar = ({ profile, ...props }) => {
    return (
        <>
            <nav className={styles.wrapper}>
                <div className={styles.logoWrapper}>
                    <img
                        alt="logo-car"
                        src={CarLogo.src}
                        className={styles.logo}
                    />
                </div>
                <LinePic pic={profile?.pictureUrl || ''} />
            </nav>
        </>
    );
};

export default Vip29LiffNavbar;
