/* eslint  @next/next/no-img-element: off */
/* eslint  no-console: off */
import { useEffect, useState } from 'react';
import { FaUser, FaChevronDown, FaChevronLeft } from 'react-icons/fa';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import Cookies from 'js-cookie';
import { titleMenuNavBar } from '../MockData/MockData';
import styles from './Vip29Navbar.module.scss';
import vip29LogoNav from '../../assets/images/logos/logo-full.png';
import flagEn from '../../assets/images/logos/flag-en.png';
import flagCn from '../../assets/images/logos/flag-cn.png';
import flagTh from '../../assets/images/logos/flag-th.png';
import useMedia from '../../../hook/useMedia';
import thumborLoader from '../../../utils/thumborLoader';

const Vip29Navbar = (props) => {
    const router = useRouter();
    const { locale, pathname, query } = router;
    const [hambugerClick, setHamburgerClick] = useState(false);
    const [renderMenu, setRenderMenu] = useState(false);
    const langSelect = [
        {
            id: 0,
            label: 'EN',
            flag: flagEn.src,
            alt: 'flag-en',
            lang: 'en',
        },
        {
            id: 1,
            label: 'ไทย',
            flag: flagTh.src,
            alt: 'flag-th',
            lang: 'th',
        },
        {
            id: 2,
            label: '中文',
            flag: flagCn.src,
            alt: 'flag-cn',
            lang: 'cn',
        },
    ];
    const accountLang = {
        th: {
            title: 'บัญชี',
            ref: 'account',
        },
        en: {
            title: 'ACCOUNT',
            ref: 'account',
        },
        cn: {
            title: '帐号',
            ref: 'account',
        },
    };
    const titleMenuAccount = {
        th: {
            title_profile: 'โปรไฟล์',
            title_logout: 'ออกจากระบบ',
        },
        en: {
            title_profile: 'PROFILE',
            title_logout: 'LOG OUT',
        },
        cn: {
            title_profile: '个人资料',
            title_logout: '登出',
        },
    };
    const triggerLang = langSelect.find((e) => e.lang === locale);
    const [currentLang, setCurrentLang] = useState(triggerLang.label);
    const [visbleDropdown, setVisibleDropdown] = useState(false);
    const [showOtherSelect, setShowOtherSelect] = useState(langSelect);
    const [modeMb, setModeMb] = useState('');
    const [accountDropdown, setAccountDropdown] = useState(false);
    const [checkCookies, setCheckCookies] = useState(null);
    const [urlUtm, setUrlUtm] = useState({
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
    });
    const page = '/member/register';
    const isMd = useMedia('(min-width: 768px)');

    const utmCheck = (utm) => {
        if (
            router.query?.utm_source &&
            router.query?.utm_medium &&
            router.query?.utm_campaign &&
            router.query?.utm_platform
        ) {
            const query = new URLSearchParams(utm).toString();
            return `?${query}`;
        } else {
            return '';
        }
    };

    const showMenuList = () => {
        setModeMb('menu');
        setHamburgerClick(!hambugerClick);
        setRenderMenu(!renderMenu);
    };

    const ICON_COLOR = '#FFFFFF';

    const Dropdown = () => {
        const toggleDropdown = () => {
            setVisibleDropdown(!visbleDropdown);
            setShowOtherSelect(langSelect);
        };

        const handleItemClick = (lang, params) => {
            const newData = langSelect.find((item) => item.lang === lang);
            setCurrentLang(newData.label);
            setVisibleDropdown(!visbleDropdown);
            if (
                router.query?.utm_source &&
                router.query?.utm_medium &&
                router.query?.utm_campaign &&
                router.query?.utm_platform
            ) {
                const {
                    utm_source,
                    utm_medium,
                    utm_campaign,
                    utm_action,
                    utm_platform,
                } = router?.query;
                const queryParams = `utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_action=${utm_action}&utm_platform=${utm_platform}`;
                router.push(
                    {
                        route: pathname,
                        query: queryParams,
                    },
                    router.asPath,
                    { locale: lang }
                );
            } else {
                router.push(
                    {
                        route: pathname,
                    },
                    router.asPath,
                    { locale: lang }
                );
            }
        };

        return (
            <div className={styles.dropdown}>
                <div className={styles.dropdownHeader} onClick={toggleDropdown}>
                    {currentLang}
                    <div className={styles.iconArrow}>
                        <FaChevronDown style={{ color: ICON_COLOR }} />
                    </div>
                </div>
                {visbleDropdown && (
                    <div className={styles.dropdownBody}>
                        {showOtherSelect?.map((item, index) => (
                            <div
                                className={styles.dropdownItem}
                                key={index}
                                onClick={(e) =>
                                    handleItemClick(item.lang, urlUtm)
                                }
                                id={item.id}
                            >
                                <img
                                    alt={item.alt}
                                    src={item.flag}
                                    className={styles.imgFlag}
                                />
                                <span>{item.label}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const scrollToContentSm = (ref, url) => {
        setHamburgerClick(!hambugerClick);
        setRenderMenu(!renderMenu);
        if (
            router.query?.utm_source &&
            router.query?.utm_medium &&
            router.query?.utm_campaign &&
            router.query?.utm_platform
        ) {
            const {
                utm_source,
                utm_medium,
                utm_campaign,
                utm_action,
                utm_platform,
            } = router?.query;
            const queryParams = `/?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_action=${utm_action}&utm_platform=${utm_platform}#${ref}`;
            router.push(queryParams);
        } else {
            const queryParams = `/#${ref}`;
            router.push(queryParams);
        }
    };

    const routePage = () => {
        if (isMd) {
            setAccountDropdown(!accountDropdown);
        } else {
            setModeMb('account');
            setHamburgerClick(!hambugerClick);
            setRenderMenu(!renderMenu);
        }
    };

    const selectLangMb = (lang) => {
        const newData = langSelect.find((item) => item.lang === lang);
        setCurrentLang(newData.label);
        router.push(
            {
                route: pathname,
                query: urlUtm,
            },
            router.asPath,
            { locale: lang }
        );
    };

    const routeAcc = (options) => {
        if (options === 'account') {
            setAccountDropdown(false);
            router.push('/member/profile');
        } else {
            setAccountDropdown(false);
            Cookies.remove('vip29_member_auth');
            router.push('/member/register', '/member/register', {
                locale: locale,
            });
        }
    };

    useEffect(() => {
        const accountChecked = Cookies.get('vip29_member_auth');
        setCheckCookies(accountChecked);
    }, []);

    useEffect(() => {
        if (
            router.query?.utm_source &&
            router.query?.utm_medium &&
            router.query?.utm_campaign &&
            router.query?.utm_platform
        ) {
            const utm_action = router.query?.utm_action
                ? router.query?.utm_action
                : '';
            const { utm_source, utm_medium, utm_campaign, utm_platform } =
                router?.query;
            setUrlUtm({
                utm_source,
                utm_medium,
                utm_campaign,
                utm_action,
                utm_platform,
            });
        } else {
            setUrlUtm({
                utm_source: '',
                utm_medium: '',
                utm_campaign: '',
                utm_action: '',
            });
        }
    }, [query]);

    return (
        <header className={styles.contentWrapper}>
            <div
                className={
                    checkCookies
                        ? styles.navigationLogin
                        : styles.navigationGuest
                }
            >
                {props?.visibleBack && (
                    <div className={styles.btnBack}>
                        <Link href={'/'} legacyBehavior>
                            <a style={{ verticalAlign: 'sub' }}>
                                <FaChevronLeft style={{ color: 'white' }} />
                            </a>
                        </Link>
                    </div>
                )}
                <div className={styles.logoCar}>
                    <Link
                        href={`/${locale === 'en' ? '' : locale}${utmCheck(
                            props?.url
                        )}#topHome`}
                        legacyBehavior
                    >
                        <a>
                            <Image
                                loader={thumborLoader}
                                alt="logo-vip-29"
                                src={vip29LogoNav.src}
                                className={styles.logoNav}
                                loading="lazy"
                                width="150"
                                height="20"
                            />
                        </a>
                    </Link>
                </div>
                <div className={styles.titleNav}>
                    {titleMenuNavBar[locale].map((item, index) => (
                        <a
                            key={index}
                            href={`/${locale === 'en' ? '' : locale}${utmCheck(
                                props?.url
                            )}#${item.ref}`}
                            className={styles.title}
                        >
                            {item.title}
                        </a>
                    ))}
                </div>
                {checkCookies && (
                    <div
                        className={styles.accountContent}
                        onClick={() => routePage()}
                    >
                        <div className={styles.accountMenu}>
                            <FaUser
                                style={{ color: ICON_COLOR }}
                                className={styles.iconUser}
                            />
                            <a className={styles.accountTitle}>
                                {accountLang[locale].title}
                            </a>
                        </div>
                        {accountDropdown && (
                            <div className={styles.dropdownAccount}>
                                <a
                                    className={styles.btnAcc}
                                    type="button"
                                    onClick={() => routeAcc('account')}
                                >
                                    {titleMenuAccount[locale].title_profile}
                                </a>
                                <a
                                    className={styles.btnAcc}
                                    type="button"
                                    onClick={() => routeAcc('logout')}
                                >
                                    {titleMenuAccount[locale].title_logout}
                                </a>
                            </div>
                        )}
                    </div>
                )}
                <div className={styles.hambugerContent}>
                    <div
                        className={`${
                            !hambugerClick
                                ? styles.hambugerMenu
                                : styles.hambugerChange
                        }`}
                        onClick={() => showMenuList()}
                    >
                        <div className={styles.barOne}></div>
                        <div className={styles.barTwo}></div>
                        <div className={styles.barThree}></div>
                    </div>
                </div>
                <Dropdown />
            </div>
            <div
                className={`${
                    renderMenu ? styles.renderMenu : styles.hideMenu
                }`}
                onClick={() => showMenuList()}
            >
                {modeMb === 'account' ? (
                    <>
                        <div className={styles.selectOptionsAccount}>
                            <a
                                className={styles.btnAcc}
                                onClick={() => routeAcc('account')}
                            >
                                {titleMenuAccount[locale].title_profile}
                            </a>
                            <a
                                className={styles.btnAcc}
                                onClick={() => routeAcc('logout')}
                            >
                                {titleMenuAccount[locale].title_logout}
                            </a>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.contentMenu}>
                            {titleMenuNavBar[locale].map((item, index) => (
                                <a
                                    className={styles.btnLink}
                                    key={index}
                                    onClick={() =>
                                        scrollToContentSm(item.ref, urlUtm)
                                    }
                                >
                                    {item.title}
                                </a>
                            ))}
                        </div>
                        <div className={styles.selectLangMb}>
                            {langSelect.map((itemMb, indexMb) => (
                                <a
                                    key={indexMb}
                                    className={styles.btnLangMb}
                                    onClick={() => selectLangMb(itemMb.lang)}
                                >
                                    {itemMb.label}
                                </a>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </header>
    );
};

export default Vip29Navbar;
