import Cookies from 'js-cookie';
import nextCookie from 'next-cookies';
import { client } from './Client';
import * as configVip29 from '../constants/vip29';

class BaseApiService {
    pinvestment_host = process.env.PINVESTMENT_API_HOST;
    pinvestment_api_version = 'api/v1';
    pinvestment_payment_host = process.env.PINVESTMENT_PAYMENT_API_HOST;
    pinvestment_payment_api_version =
        process.env.PINVESTMENT_PAYMENT_API_VERSION;
    vip29_api_host = process.env.VIP29_API_HOST;
    vip29_api_version = 'api/v1';
    host = process.env.HOST;
    api_version = process.env.API_VERSION;
    api_prefix = process.env.API_PREFIX;
    api = client;
    admin_token_name = configVip29.AuthConfigs.admin_token_name;

    constructor() {
        this._reqConfig = this._reqConfig.bind(this);
        this.getUrl = this.getUrl.bind(this);
        this.setCookie = this.setCookie.bind(this);
        this.delCookie = this.delCookie.bind(this);
    }

    _reqConfig = (context = null) => {
        let token;
        if (context !== null) {
            const next_cookies = nextCookie(context);
            token = next_cookies?.[this.admin_token_name];
        } else {
            token = Cookies.get(this.admin_token_name);
        }
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            },
        };
    };

    _reqPinvestmentConfig = () => {
        const pinvestment_line_api_token =
            process.env.PINVESTMENT_LINE_API_TOKEN;
        return {
            headers: {
                Authorization: `Bearer ${pinvestment_line_api_token}`,
                Accept: 'application/json',
            },
        };
    };

    getUrl = (path, context = null) => {
        return context === null
            ? `${this.api_version}/_${this.api_prefix}${path}`
            : `${this.host}${this.api_version}/_${this.api_prefix}${path}`;
    };

    getBookingPinvestUrl = (path, context = null) => {
        const prefix = 'pinvestment';
        return context === null
            ? `${this.api_version}/${prefix}${path}`
            : `${this.host}${this.api_version}/${prefix}${path}`;
    };

    getPinvestmentUrl = (path) => {
        // const host = process.env.PINVESTMENT_API_HOST;
        // const api_pinvestment_version = 'v1';
        return `${this.pinvestment_host}/${this.pinvestment_api_version}${path}`;
    };

    getPinvestmentPaymentService = (path) => {
        return `${this.pinvestment_payment_host}/${this.pinvestment_payment_api_version}${path}`;
    };

    getVip29ServiceUrl = (path) => {
        return `${this.vip29_api_host}/${this.vip29_api_version}${path}`;
    };

    getCookie = (cookieName) => {
        return Cookies.get(cookieName) || null;
    };

    setCookie = (cookieName, payloads) => {
        Cookies.set(cookieName, payloads);
    };

    delCookie = (cookieName) => {
        if (
            cookieName === this.admin_token_name &&
            typeof window !== 'undefined'
        ) {
            localStorage.removeItem('admin_profile');
        }
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };
}

export default BaseApiService;
