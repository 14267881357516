/* eslint no-console: off */
/* eslint react-hooks/exhaustive-deps: off */
import { Backdrop, CircularProgress } from '@mui/material';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import * as gtag from '../../../utils/gtag';
import LiffClientService from '../../../services/LiffClientService';
import Vip29ApiService from '../../../services/Vip29ApiService';
import Vip29Footer from '../../components/Vip29Footer';
import Vip29LiffNavbar from '../../components/Vip29LiffNavbar';
import Vip29Navbar from '../../components/Vip29Navbar';
import { trackUtm } from '../../../utils/trackUtm';
import IconLine from '../../components/IconLine';

const RenderLiff = ({ liffProfileData, loading, ...props }) => {
    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
            </Head>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {!loading && (
                <>
                    <Vip29LiffNavbar profile={liffProfileData} />
                    {props.children}
                </>
            )}
        </>
    );
};

const ClientLayout = (props) => {
    const router = useRouter();
    const queryString = router?.query;
    const [url, setUrl] = useState(null);
    const [liffProfileData, setLiffProfileData] = useState({});
    const [lineBrowser, setLineBrowser] = useState(false);
    const [loading, setLoading] = useState(false);

    const checkIsLineBrowser = (subscribe) => {
        let is_correct = false;
        if (subscribe.mounted) {
            const { userAgent } = navigator;
            is_correct = userAgent.includes('Line');
        }
        setLineBrowser(is_correct);
        return is_correct;
    };

    const loadProfile = async (subscribe, liff) => {
        if (!subscribe.mounted) return;
        const liffProfile = await liff.getProfile();
        setLiffProfileData(liffProfile);
        return liffProfile;
    };

    const initLiff = async (subscribe) => {
        if (!subscribe.mounted) return;
        const liff = (await LiffClientService()).default;
        const { liffId } = props;
        try {
            await liff.init({ liffId });
            await liff.ready;
            return liff;
        } catch (error) {
            console.error('liff init error', error.message);
        }
        // or init with options
        // if (!liff.isLoggedIn()) {
        //     liff.login();
        // }
    };

    const checkLiffMember = async (profile) => {
        // await check api member register
        let is_already_member = false;
        try {
            const data = await Vip29ApiService.checkMemberIsAlready(
                profile.userId
            );
            is_already_member = data?.id ? true : false;
        } catch (e) {
            if (e.status === 404 && e.message === 'NOT_FOUND_MEMBER') {
                is_already_member = false;
            }
        }
        return is_already_member;
    };

    useEffect(() => {
        const subscribe = {
            mounted: true,
        };
        setLoading(true);
        (async () => {
            if (Object.keys(queryString).length !== 0) {
                const {
                    utm_source,
                    utm_medium,
                    utm_campaign,
                    utm_action,
                    utm_platform,
                } = router?.query;
                setUrl({
                    utm_source,
                    utm_medium,
                    utm_campaign,
                    utm_action,
                    utm_platform,
                });
            }
            const is_line_browser = checkIsLineBrowser(subscribe);
            try {
                if (is_line_browser) {
                    const liff = await initLiff(subscribe);
                    // const isInLineInAppBrowser = liff.isInClient();
                    const profile = await loadProfile(subscribe, liff);
                    if (typeof props.callbackData === 'function') {
                        props.callbackData({
                            is_line_browser,
                            liff_profile: profile,
                            loading: false,
                            liff,
                        });
                    }
                } else {
                    if (typeof props.callbackData === 'function') {
                        props.callbackData({
                            loading: false,
                        });
                    }
                }
                setLoading(false);
            } catch (e) {
                if (typeof props.callbackData === 'function') {
                    props.callbackData({
                        is_line_browser,
                        loading: false,
                    });
                }
                console.error(e);
                setLoading(false);
            }
        })();
        return () => (subscribe.mounted = false);
    }, []);

    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        try {
            // track utm
            const querys = Object.keys(router?.query);
            if (
                querys.length > 0 &&
                querys.includes('utm_source') &&
                querys.includes('utm_medium') &&
                querys.includes('utm_campaign') &&
                querys.includes('utm_action')
            ) {
                trackUtm(router);
            }
        } catch (e) {
            console.error(e);
        }
    }, [router.query]);

    const styleContact = props.style;
    const isVisible = props.isVisible ? props.isVisible : false;
    const isVisibleBtnBack = props?.visibleBack;

    return (
        <>
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            />

            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    // Global Config
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADWORD}');
                `}
            </Script>
            <div className="client-layout">
                {lineBrowser ? (
                    <RenderLiff
                        liffProfileData={liffProfileData}
                        loading={loading}
                    >
                        {props.children}
                    </RenderLiff>
                ) : (
                    <>
                        <Vip29Navbar url={url} visibleBack={isVisibleBtnBack} />
                        <IconLine />
                        {props.children}
                        <Vip29Footer
                            style={styleContact}
                            isVisible={isVisible}
                            url={url}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default ClientLayout;
