import Thumbor from 'thumbor';

export default function thumborLoader({ src, width, quality }) {
    if (process.env.NEXT_PUBLIC_USE_THUMBOR === 'YES') {
        const host = process.env.NEXT_PUBLIC_HOST;
        const thumborHost = process.env.NEXT_PUBLIC_THUMBOR_HOST;
        const thumborToken =
            process?.env?.NEXT_PUBLIC_THUMBOR_TOKEN || 'unsafe';
        const thumbor = new Thumbor(thumborToken, thumborHost);
        const thumborUrl = thumbor
            .setImagePath(`${host}${src}`)
            .resize(width, 0)
            .buildUrl();
        return thumborUrl;
    } else {
        return src;
    }
}
