import Image from 'next/image';
import styles from './IconLine.module.scss';
import IconLineImg from '../../assets/images/logos/line.png';

const IconLine = () => {
    return (
        <div className={styles.iconLineContainer}>
            <a href="https://lin.ee/psfhiul" target="_blank" rel="noreferrer">
                <Image
                    src={IconLineImg.src}
                    width={60}
                    height={60}
                    alt="icon-line"
                />
            </a>
        </div>
    );
};

export default IconLine;
