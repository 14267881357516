/* eslint  @next/next/no-img-element: off */
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styles from './Vip29Footer.module.scss';
import ghIcon from '../../assets/images/home/footer/gh-icon-footer.png';
import lrIcon from '../../assets/images/home/footer/lr-icon-footer.png';
import msIcon from '../../assets/images/home/footer/ms-icon-footer.png';
import { titleFooter } from '../MockData/MockData';
import thumborLoader from '../../../utils/thumborLoader';

const Vip29Footer = ({ style = {}, isVisible = false, url }) => {
    const router = useRouter();
    const { locale } = router;
    const linkByIcon = [
        {
            image: ghIcon.src,
            link: 'https://greenhousebistro.co/home',
            alt: 'greenHouse',
        },
        {
            image: lrIcon.src,
            link: 'https://luxury29.io/',
            alt: 'luxury',
        },
        {
            image: msIcon.src,
            link: 'https://monosei.com/?fbclid=IwAR05tIvs5vbO0WWnq6o_zWfAWLNdRVQvY9ktr96mQJMyKiwgY5BDPg_9ivQ',
            alt: 'monoSei',
        },
    ];

    const utmCheck = (utm) => {
        if (utm === null || utm === undefined) {
            return '';
        } else {
            const query = new URLSearchParams(utm).toString();
            return `?${query}`;
        }
    };

    return (
        <div className={styles.sectionFooter} style={style}>
            {!isVisible && (
                <div className={styles.contentFooter}>
                    <div className={styles.production}>
                        <span>Production of E29 GROUP</span>
                        <div className={styles.imgProduction}>
                            {linkByIcon.map((itemImg, indexImg) => (
                                <a href={itemImg.link} key={indexImg}>
                                    <Image
                                        loader={thumborLoader}
                                        src={itemImg.image}
                                        alt={itemImg.alt}
                                        className={styles.iconLogo}
                                        width="65"
                                        height="65"
                                        loading="lazy"
                                    />
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.footerNavList}>
                        {titleFooter.map((item, index) => (
                            <div className={styles.listContent} key={index}>
                                <div className={styles.headTitle}>
                                    <span>{item.headTitle[locale]}</span>
                                </div>
                                {item.subTitle && (
                                    <>
                                        <div className={styles.subTitle}>
                                            <span>{item.subTitle[locale]}</span>
                                        </div>
                                    </>
                                )}
                                {item.childTitle &&
                                    item.childTitle.map(
                                        (childItem, childIndex) => (
                                            <div
                                                className={styles.childTitle}
                                                key={childIndex}
                                            >
                                                <Link
                                                    href={`/${locale === 'en'
                                                        ? ''
                                                        : locale
                                                        }/${childItem.link
                                                        }/${utmCheck(url)}`}
                                                    legacyBehavior
                                                >
                                                    <a>
                                                        {
                                                            childItem.title?.[
                                                            locale
                                                            ]
                                                        }
                                                    </a>
                                                </Link>
                                            </div>
                                        )
                                    )}
                                {item.contact.length > 0 &&
                                    item.contact.map(
                                        (contactItem, contactIndex) => (
                                            <div
                                                className={styles.contactTitle}
                                                key={contactIndex}
                                            >
                                                <a>{contactItem.title}</a>
                                            </div>
                                        )
                                    )}
                                {item.social && (
                                    <>
                                        <div className={styles.socialContent}>
                                            {item.social.map(
                                                (itemSocial, indexSocial) => (
                                                    <a
                                                        className={
                                                            styles.socialList
                                                        }
                                                        key={indexSocial}
                                                        href={itemSocial.link}
                                                        rel="nofollow"
                                                    >
                                                        <Image
                                                            loader={
                                                                thumborLoader
                                                            }
                                                            width="30"
                                                            height="30"
                                                            alt={itemSocial.alt}
                                                            src={itemSocial.src}
                                                        />
                                                    </a>
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={styles.cop}>
                        <span>
                            © {new Date().getFullYear()} Luxury29 Car Rental, All rights
                            reserved
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Vip29Footer;
