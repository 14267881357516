import BaseApiService from './BaseApiService';
import checkAuthorizeReject from '../server/utils/checkAuthorizeReject';
import * as configVip29 from '../constants/vip29';
import { base64Encode } from '../server/utils/transactionSymmetricEncryption';

class Vip29ApiService extends BaseApiService {
    trackUtm = async (formData) => {
        const url = this.getVip29ServiceUrl(`/tracker/send`);
        try {
            const res = await this.api.post(
                url,
                formData,
                this.getHeaderGuest()
            );
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    checkMemberIsAlready = async (line_id) => {
        const url = this.getVip29ServiceUrl(`/members/${line_id}/check`);
        try {
            const res = await this.api.get(url, this.getHeaderGuest());
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    registerMember = async (formData) => {
        const url = this.getVip29ServiceUrl(`/members/register`);
        try {
            const res = await this.api.post(
                url,
                formData,
                this.getHeaderGuest()
            );
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    bookingMemberList = async () => {
        const url = this.getVip29ServiceUrl(`/members/bookings`);
        try {
            const res = await this.api.get(url, this.getHeaderMember());
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    bookingMemberCreate = async (formData) => {
        const url = this.getVip29ServiceUrl(`/members/bookings`);
        try {
            const res = await this.api.post(
                url,
                formData,
                this.getHeaderMember()
            );
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    bookingDetail = async (id) => {
        const url = this.getVip29ServiceUrl(`/members/bookings/${id}`);
        try {
            const res = await this.api.get(url, this.getHeaderGuest());
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    bookingGuestCreate = async (formData) => {
        const url = this.getVip29ServiceUrl(`/members/guest/bookings`);
        try {
            const res = await this.api.post(
                url,
                formData,
                this.getHeaderGuest()
            );
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    bookingUpdate = async (id, formData) => {
        const url = this.getVip29ServiceUrl(`/members/bookings/${id}/edit`);
        try {
            const res = await this.api.post(
                url,
                formData,
                this.getHeaderGuest()
            );
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    bookingChargeCreate = async (booking_id, formData) => {
        const url = this.getVip29ServiceUrl(
            `/members/bookings/${booking_id}/create/charge`
        );
        try {
            const res = await this.api.post(
                url,
                formData,
                this.getHeaderGuest()
            );
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    getBookingChargeId = async (booking_id) => {
        const url = this.getVip29ServiceUrl(
            `/members/bookings/${booking_id}/charge`
        );
        try {
            const res = await this.api.get(url, this.getHeaderGuest());
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    getCarList = async () => {
        const url = this.getVip29ServiceUrl(`/members/bookings/cars`);
        try {
            const res = await this.api.get(url, this.getHeaderGuest());
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    getCarListTest = async () => {
        const cars = [
            {
                id: 1,
                name: 'ROLLS ROYCE (PHANTOM)',
                price: {
                    full_day: {
                        name: 'Full Day',
                        amount: 20,
                    },
                    pick_up: null,
                },
                car_brand: {
                    id: 1,
                    name: 'ROLLS ROYCE',
                },
                car_model: {
                    id: 1,
                    name: 'PHANTOM',
                },
            },
            {
                id: 2,
                name: 'BENTLEY (Flying SPUR w12)',
                price: {
                    full_day: {
                        name: 'Full Day',
                        amount: 20,
                    },
                    pick_up: null,
                },
                car_brand: {
                    id: 2,
                    name: 'BENTLEY',
                },
                car_model: {
                    id: 2,
                    name: 'Flying SPUR w12',
                },
            },
            {
                id: 3,
                name: 'Maybach (S600)',
                price: {
                    full_day: {
                        name: 'Full Day',
                        amount: 20,
                    },
                    pick_up: null,
                },
                car_brand: {
                    id: 3,
                    name: 'Mercedes Maybach',
                },
                car_model: {
                    id: 3,
                    name: 'S600',
                },
            },
            {
                id: 4,
                name: 'BENZ (S500E)',
                price: {
                    full_day: {
                        name: 'Full Day',
                        amount: 20,
                    },
                    pick_up: null,
                },
                car_brand: {
                    id: 3,
                    name: 'BENZ',
                },
                car_model: {
                    id: 4,
                    name: 'S500E',
                },
            },
            {
                id: 5,
                name: 'BENTLEY (BENTAYGA)',
                price: {
                    full_day: {
                        name: 'Full Day',
                        amount: 20,
                    },
                    pick_up: null,
                },
                car_brand: {
                    id: 2,
                    name: 'BENTLEY',
                },
                car_model: {
                    id: 5,
                    name: 'BENTAYGA',
                },
            },
            {
                id: 6,
                name: 'AUDI (Q7 45TFSI)',
                price: {
                    full_day: {
                        name: 'Full Day',
                        amount: 20,
                    },
                    pick_up: null,
                },
                car_brand: {
                    id: 2,
                    name: 'AUDI',
                },
                car_model: {
                    id: 6,
                    name: 'Q7 45TFSI',
                },
            },
            {
                id: 7,
                name: 'TOYOTA ALPHARD 2.5 Z Van 2WD 2024',
                price: {
                    full_day: {
                        name: 'Full Day',
                        amount: 20,
                    },
                    pick_up: null,
                },
                car_brand: {
                    id: 2,
                    name: 'TOYOTA',
                },
                car_model: {
                    id: 7,
                    name: 'ALPHARD 2.5 Z Van 2WD 2024',
                },
            },
        ];
        return cars;
    };

    verifyCode = async (payloads) => {
        try {
            const res = await this.api.post(
                this.getVip29ServiceUrl(`/members/verify-code/send`),
                payloads
            );
            if (res.status === 200) {
                const { data } = res.data;
                return data;
            }
        } catch (e) {
            const message = e.response.data.data.message || e?.message;
            const status = e.response.data.data.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    loginWithSetProfile = async (payloads) => {
        try {
            const res = await this.api.post(
                this.getVip29ServiceUrl(`/members/login`),
                payloads
            );
            if (res.status === 200) {
                const { data } = res.data;
                this.setCookie(
                    configVip29.AuthConfigs.member_token_name,
                    data.access_token
                );
                const profile = await this.getProfile(data.access_token);
                return profile;
            }
        } catch (e) {
            const message = e.response.data.data.message || e?.message;
            const status = e.response.data.data.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    loginWithSetAccessTokenOnly = async (payloads) => {
        try {
            const res = await this.api.post(
                this.getVip29ServiceUrl(`/members/login`),
                payloads
            );
            if (res.status === 200) {
                const { data } = res.data;
                this.setCookie(
                    configVip29.AuthConfigs.member_token_name,
                    data.access_token
                );

                return data;
            }
        } catch (e) {
            const message = e.response.data.data.message || e?.message;
            const status = e.response.data.data.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    updateTracked = async (id, tracked) => {
        try {
            const res = await this.api.post(
                this.getVip29ServiceUrl(`/members/tracked/${id}`),
                { tracked: tracked }
            );
            if (res.status === 200) {
                const { data } = res.data;
                return data;
            }
        } catch (e) {
            const message = e.response?.data?.data?.message || e?.message;
            const status = e.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    getProfile = async (access_token) => {
        try {
            const res = await this.api.get(
                this.getVip29ServiceUrl(`/members/profile`),
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        Accept: 'application/json',
                    },
                }
            );
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e.response.data.data.message || e?.message;
            const status = e.response.data.data.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    sendContact = async (form) => {
        const url = this.getVip29ServiceUrl('/members/contact/create');
        try {
            const res = await this.api.post(url, form, this.getHeaderMember());
            const { data } = res.data;
            return data;
        } catch (e) {
            const message = e?.response?.data?.data?.message || e?.message;
            const status = e?.response?.data?.data?.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    logout = async (access_token) => {
        this.delCookie(configVip29.AuthConfigs.member_token_name);
        try {
            const res = await this.api.post(
                this.getVip29ServiceUrl(`/members/logout`),
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        Accept: 'application/json',
                    },
                }
            );
            if (res.status === 200) {
                return true;
            }
        } catch (e) {
            const message = e.response.data.data.message || e?.message;
            const status = e.response.data.data.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    lineNotify = async (booking_id, message, target) => {
        this.delCookie(configVip29.AuthConfigs.member_token_name);
        try {
            const res = await this.api.post(
                this.getVip29ServiceUrl(
                    `/members/bookings/${booking_id}/line-noti`
                ),
                {
                    target,
                    message,
                },
                this.getHeaderGuest()
            );
            if (res.status === 200) {
                return true;
            }
        } catch (e) {
            const message = e.response.data.data.message || e?.message;
            const status = e.response.data.data.status || 500;
            throw {
                message,
                status,
            };
        }
    };

    checkIsMemberLogIn = () => {
        const token = this.getCookie(configVip29.AuthConfigs.member_token_name);
        return {
            status: token !== null ? true : false,
            token,
        };
    };

    getHeaderMember = () => {
        const vip29_api_key = base64Encode(
            `${process.env.VIP29_API_APP_ID}:${process.env.VIP29_API_APP_SECRET}`
        );
        const token = this.getCookie(configVip29.AuthConfigs.member_token_name);
        const agent = checkAuthorizeReject();
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'x-api-key': vip29_api_key,
                // 'ngrok-skip-browser-warning': 'latest'
            },
            httpsAgent: agent,
        };
    };

    getHeaderGuest = () => {
        const vip29_api_key = base64Encode(
            `${process.env.VIP29_API_APP_ID}:${process.env.VIP29_API_APP_SECRET}`
        );
        const agent = checkAuthorizeReject();
        return {
            headers: {
                Accept: 'application/json',
                'x-api-key': vip29_api_key,
                // 'ngrok-skip-browser-warning': 'latest'
            },
            httpsAgent: agent,
        };
    };
}

export default new Vip29ApiService();
