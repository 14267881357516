import { useEffect, useState } from 'react';

const useMedia = (query) => {
    const [media, setMedia] = useState(null);
    const [matches, setMatches] = useState(null);

    useEffect(() => {
        const _media = window.matchMedia(query);
        if (_media) {
            setMedia(_media);
            setMatches(_media?.matches);
        }
    }, [query]);

    useEffect(() => {
        const handler = (e) => setMatches(e.matches);
        media?.addListener(handler);
        return () => media?.removeListener(handler);
    }, [media]);

    return matches;
};

export default useMedia;
