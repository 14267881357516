const https = require('https');

module.exports = () => {
    const dev = process.env.NODE_ENV === 'development' ? false : true;
    const agent = new https.Agent({
        rejectUnauthorized: dev,
    });

    return agent;
};
